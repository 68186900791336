import React from 'react';
import Avatar from '@material-ui/core/Avatar';
//import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslate, Title } from 'react-admin';
import Button from '@material-ui/core/Button';
//import Modal from 'react-modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth } from 'aws-amplify';





 class SignUp extends React.Component{

    constructor(props){
  super(props);
  this.state={
      firstName:'',
      lastName:'',
  email:'',
  password:'',
  step:0,
  username:'',
  code:'',
  target:true,
  }
 }


 sign = () => {

      this.props.history.push('/Login');


    }

confirmSignUp = async () => {
  this.setState({target:false})
  let username = this.state.username;
  let code = this.state.code;

      await Auth.confirmSignUp(username,code).then(data => {
            console.log('successful confirm sign up',data);
            //this.setState({step:1})
            this.setState({target:true})
            this.props.history.push('/Login');
        })
        	.catch((error)=> {
    console.log(error);   
     alert("Please Enter valid Email or Code !");
   })


    }

    signUp = () => {

      this.props.history.push('/SignUp');


    }

handleUser= (e) => {

        this.setState({username : e.target.value});

    }

    handleCode= (e) => {

        this.setState({code : e.target.value});

    }

 handleClickEmail = (e) => {

        this.setState({email : e.target.value});

    }

    handleClickPwd = (e) => {

        this.setState({password : e.target.value});

    }

    handleClickFirst = (e) => {

        this.setState({firstName : e.target.value});

    }

    handleClickLast = (e) => {

        this.setState({lastName : e.target.value});

    }

    handleClickSignUp = async () => {

      let email = this.state.email;
      let password = this.state.password;
      let firstName = this.state.firstName;

     await  Auth.signUp({
            username : email,
            password : password,
            attributes: {
                email : email,          // optional
                //phone_number,   // optional - E.164 number convention
                // other custom attributes 
            }
        }).then(data => {
            console.log('successful sign up',data);
            this.setState({step:1})
            //this.props.history.push('/Login');
        })
        	.catch((error)=> {
    console.log(error);   
     //alert("Please Enter Right Email or Password !");
   })
        	
       

    }

 render(){

 

    

    return(


        <Container component="main" maxWidth="xs">
{this.state.step === 0 ?
<div>
<div style={{display: 'flex',  justifyContent:'center',marginTop:50}}>

   

    <Typography component="h1" variant="h5" style={{marginLeft: '0.8rem'}}>
          Sign Up
        </Typography>

        </div>

<form noValidate>

     <TextField
            variant="outlined"
            margin="normal"
            //required
            fullWidth
            name="firstName"
            label="First Name"
            //type="password"
            id="firstName"
            value={this.state.firstName}
            //autoComplete="current-password"
            onChange = {this.handleClickFirst}
          />

           <TextField
            variant="outlined"
            margin="normal"
            //required
            fullWidth
            name="lastName"
            label="Last Name"
            //type="password"
            id="lastName"
            value={this.state.lastName}
            //autoComplete="current-password"
            onChange = {this.handleClickLast}
          />
          <TextField
            variant="outlined"
            margin="normal"
            //required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            //autoComplete="email"
            value={this.state.email}
            //autoFocus
            onChange = {this.handleClickEmail}
          />
          <TextField
            variant="outlined"
            margin="normal"
            //required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            //autoComplete="current-password"
            value={this.state.password}
            onChange = {this.handleClickPwd}
          />
         
          {this.state.target ?
          <Button
            //type="submit"
            fullWidth
            variant="contained"
            style={{backgroundColor:'#f79403',color:'white'}}
            //color="#f79403"
            //className={classes.submit}
            onClick={this.handleClickSignUp}
          >
            Create Account
          </Button> :
           <div style={{display: 'flex',alignContent:"center", alignItems:"center",  justifyContent:'center',marginTop:50}}>
          <CircularProgress  color="secondary" />
          </div>}
           <Grid container style={{marginTop:20,display: 'flex',  justifyContent:'center'}}>
            
            <Grid item onClick = {this.sign}>
             <u>
                {"Back to Sign In"}
                </u>
              
            </Grid>
          </Grid>
          
        </form>

        </div>

:
   <div>     
<div style={{display: 'flex',  justifyContent:'center',marginTop:50}}>
        <Typography component="h1" variant="h5" style={{marginLeft: '0.8rem'}}>
          Confirm Sign Up
        </Typography>

        </div>

<form noValidate>

     <TextField
            variant="outlined"
            margin="normal"
            //required
            fullWidth
            name="firstName"
            label="Email"
            //type="password"
            id="firstName"
            value={this.state.username}
            //autoComplete="current-password"
            onChange = {this.handleUser}
          />

           <TextField
            variant="outlined"
            margin="normal"
            //required
            fullWidth
            name="lastName"
            label="Code"
            //type="password"
            id="lastName"
            value={this.state.code}
            //autoComplete="current-password"
            onChange = {this.handleCode}
          />
          
         {this.state.target ?
          <Button
            //type="submit"
            fullWidth
            variant="contained"
            style={{backgroundColor:'#f79403',color:'white'}}
            //color="#f79403"
            //className={classes.submit}
            onClick={this.confirmSignUp}
          >
            Confirm Account
          </Button>: 
          <div style={{display: 'flex',alignContent:"center", alignItems:"center",  justifyContent:'center',marginTop:50}}>
          <CircularProgress color="secondary" />
          </div>
        }
           <Grid container style={{marginTop:20,display: 'flex',  justifyContent:'center'}}>
            
            <Grid item onClick = {this.signUp}>
             <u>
                {"Back to Sign Up"}
                </u>
              
            </Grid>
               
              
          </Grid>
          
          
        </form>

        <p style={{marginTop:20,display: 'flex',  justifyContent:'center',fontSize:18,color:'green'}}>
                A verification code sent to your provided Email.
                </p>

        </div>

  
   }

       </Container>


    )

 }

 }

 export default SignUp;