import React from 'react';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import Modal from '@material-ui/core/Modal';
import Tooltip from '@material-ui/core/Tooltip';
import CreateIcon from '@material-ui/icons/Create';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'underscore';
import { Auth, API } from 'aws-amplify';

class TemplateList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: '',
            dataArr: [],
            newArr: [],
            category:[],
            target: false,
            setOpen: false,
            deleleData: {},
            prodValue: 'All',
            prod: 'All',
            productArr: [],
            changeState: false,
            filterList: [],
            noRecord: false
        }

        this.click = this.click.bind(this)
    }

    async componentDidMount() {
        const apiName = 'mahesh';
        const path = '/mahesh';
        const myInit = { // OPTIONAL
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            },
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            // queryStringParameters: {  // OPTIONAL
            //     name: 'param',
            // },

        };

        await API
            .get(apiName, path, myInit)
            .then(response => {
                let resData = []
                let resArr = []
                _.each(response.data, (resDat) => {
                    if (resDat.ProductCategory.length > 2 && !resArr.includes(resDat.ProductCategory) && (typeof resDat.ProductCategory) === "string") {
                        resArr.push(resDat.ProductCategory)
                        resData.push({ category: resDat.ProductCategory })
                    }
                })
                this.setState({ category: resData, newArr: response.data, target: true, setOpen: false })
            })
            .catch(error => {
                // console.log(error.response);
            });
    }

    handleClose = () => {
        this.setState({ setOpen: false })
    };
    cancel = () => {
        this.setState({ setOpen: false })
    };
    phoneClick = (data) => {
        this.setState({ setOpen: true, deleleData: data })
    }
    editItem = (data) => {
        this.props.history.push({
            pathname: '/editTemplate',
            state: { name: data, dataArray: this.state.newArr }
        })
    }
    deleteItem = async (item) => {
        const apiName = 'mahesh';
        const path = '/mahesh';
        const myInitDelete = { // OPTIONAL
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            },
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            body:
            {
                "Key": {
                    "id": item.id,
                },
                "TableName": "products"
            }
        }
        await API
            .del(apiName, path, myInitDelete)
            .then(response => {
                //this.setState({setOpen:false})
                this.componentDidMount()
            })
            .catch(error => {
                // console.log(error.response);
            });
    }

    prodCat = (event) => {
        let prodArr = [];
        _.each(this.state.newArr, function (dataC) {
            if (dataC.Company == event.target.value) {
                prodArr.push(dataC.ProductName);
            }
        })
        this.setState({ prodValue: event.target.value, productArr: prodArr, changeState: true })
    }

    prod = (event, value) => {
        this.setState({ prod: (value && value.category) ? value.category : "" })
    }

    click = () => {
        const { prodValue, prod, noRecord } = this.state
        let filterList = [];
        let arr = [];
        let arrOne = [];
        if (prod == "All") {
            if (prod == "All" && prodValue == "All") {
                _.each(this.state.newArr, dat => {
                    this.setState({ noRecord: false })
                    filterList.push(dat);
                })
            }
            _.each(this.state.newArr, dataCo => {
                if (dataCo.Company === prodValue) {
                    this.setState({ noRecord: false })
                    filterList.push(dataCo);
                }
            })
        } else {
            if (prodValue == "All") {
                _.each(this.state.newArr, dataCo => {
                    if (dataCo.ProductCategory === prod) {
                        this.setState({ noRecord: false })
                        filterList.push(dataCo);
                    } else {
                        arrOne.push(dataCo);
                        if (this.state.newArr.length === arrOne.length) {
                            this.setState({ noRecord: true })
                        }
                    }
                })
            } else {
                _.each(this.state.newArr, dataCo => {
                    if (dataCo.Company === prodValue && dataCo.ProductCategory === prod) {
                        filterList.push(dataCo);
                        this.setState({ noRecord: false })
                    } else {
                        arr.push(dataCo);
                        if (this.state.newArr.length === arr.length) {
                            this.setState({ noRecord: true })
                        }
                    }
                })
            }
        }
        this.setState({ filterList: filterList })
    }

    render() {
        if (!this.state.target) {
            return <div style={{ display: 'flex', justifyContent: 'center', marginTop: 150 }}>
                <CircularProgress size={100} color="secondary" />
            </div>
        }

        const body = (
            <div style={{ backgroundColor: 'white', position: 'absolute', top: 120, left: 520, border: '2px solid #f79403', paddingLeft: 30, paddingRight: 30 }}>
                <p id="simple-modal-description">
                    <p>Are you sure you want to delete this product?</p>
                    <Button
                        variant="contained"
                        style={{ justifyContent: "center", alignItems: "center", alignContent: "center", width: 100, height: 30, backgroundColor: '#f79403', color: 'white', marginTop: 10, marginLeft: 40 }}
                        onClick={() => this.deleteItem(this.state.deleleData)}
                    >
                        Delete
                    </Button>
                    <Button
                        variant="contained"
                        style={{ justifyContent: "center", alignItems: "center", alignContent: "center", width: 100, height: 30, backgroundColor: '#f79403', color: 'white', marginTop: 10, marginLeft: 40 }}
                        onClick={() => this.cancel()}
                    >
                        Cancel
                    </Button>
                </p>
            </div>
        );

        let filterListComp = [];
        _.each(this.state.newArr, function (comp) {
            filterListComp.push(comp.Company);
        })
        var filteredArray = filterListComp.filter(function (item, pos) {
            return filterListComp.indexOf(item) == pos;
        });

        return (
            <div>
                <div >
                    <Grid container>
                        <Grid item xs={12} sm={3}>
                            <FormControl variant="outlined" style={{ minWidth: 250, marginTop: 16, marginLeft: 30 }}>
                                <InputLabel id="demo-simple-select-outlined-label">Select Company</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.prodValue}
                                    onChange={(event) => this.prodCat(event)}
                                    label="Select Company"
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    {/* {filteredArray.map(dataComp => (
            <MenuItem value={dataComp}>{dataComp}</MenuItem>
         ))}  */}
                                    <MenuItem value="GCMC">GCMC</MenuItem>
                                    <MenuItem value="Crown Polymers">Crown Polymers</MenuItem>
                                    <MenuItem value="Polycoat">Polycoat</MenuItem>
                                    <MenuItem value="Polytuff">Polytuff</MenuItem>
                                    <MenuItem value="EverRoof">EverRoof</MenuItem>
                                    <MenuItem value="UPC">UPC</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl variant="outlined" style={{ minWidth: 250, marginTop: 16, marginLeft: 50 }}>
                                {/* <InputLabel id="demo-simple-select-outlined-label">Select Category</InputLabel> */}
                                <Autocomplete
                                    id="combo-box-demo"
                                    autoSelect={true}
                                    options={this.state.category}
                                    // options={[{"category":"All"},{"category":"Spray Foam"},{"category": "Silicone Coatings"}]}
                                    getOptionLabel={(option) => option.category}
                                    style={{
                                        // marginLeft: -50,
                                        height: 40,
                                        width: 300
                                    }}
                                    value={{ category: this.state.prod }}
                                    onChange={this.prod}
                                    renderInput={(params) =>
                                        <TextField {...params} label="Select Category" variant="outlined"
                                            value={this.state.prod}
                                        // onChange={(e)=>this.handleStatusChange(e)}
                                        />}
                                />

                            </FormControl>
                            <Button
                                variant="contained"
                                style={{ width: 80, height: 45, backgroundColor: '#f79403', color: 'white', marginLeft: 30, marginTop: 20 }}
                                onClick={() => this.click()}
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <br /><br />
                {this.state.noRecord ?
                    <p style={{ marginLeft: 20 }}>No Record Found!</p>
                    :
                    <Table>
                        {this.state.filterList.length > 0 ?
                            <TableBody>
                                {this.state.filterList.map(dataF => (
                                    <Card style={{
                                        borderRadius: 25,
                                        border: "1px solid rgb(210, 201, 201)",
                                        padding: 5, margin: 10
                                    }} >
                                        <Grid container spacing={6}>
                                            <Grid item xs={12} sm={10}>
                                                <TableRow>
                                                    <TableCell style={{ borderBottom: "none", fontFamily: 'Times New Roman', fontSize: 16, padding: 10 }}>
                                                        <b>Company :</b> {dataF.Company} <br />
                                                        <b>Poduct Name :</b> {dataF.ProductName} <br />
                                                        <b>Poduct Category :</b> {dataF.ProductCategory} <br />
                                                        <b> Product Code :</b> {dataF.ProductCode} <br />
                                                    </TableCell>
                                                </TableRow>
                                            </Grid>
                                            <Grid item xs={12} sm={2}>
                                                <div style={{ marginTop: 10 }}>
                                                    <Tooltip title="Edit" aria-label="Edit">
                                                        <CreateIcon onClick={() => this.editItem(dataF)} style={{ cursor: 'pointer', fontSize: 20, color: "#7f2a2a" }} />
                                                    </Tooltip>
&nbsp;&nbsp;&nbsp;
                  <Tooltip title="Delete" aria-label="Delete">
                                                        <DeleteIcon onClick={() => this.phoneClick(dataF)} style={{ cursor: 'pointer', fontSize: 20, color: "#7f2a2a" }} />
                                                    </Tooltip>

                                                    <Modal
                                                        open={this.state.setOpen}
                                                        onClose={this.handleClose}
                                                        aria-labelledby="simple-modal-title"
                                                        aria-describedby="simple-modal-description"
                                                    //style={{backgroundColor:"white"}}
                                                    >
                                                        {body}
                                                    </Modal>
                                                </div>
                                            </Grid>

                                        </Grid>

                                    </Card>
                                ))}
                            </TableBody>
                            :
                            <TableBody>
                                {this.state.newArr.map(dataOne => (
                                    <Card style={{
                                        borderRadius: 25,
                                        border: "1px solid rgb(210, 201, 201)",
                                        padding: 5, margin: 10
                                    }} >
                                        <Grid container spacing={6}>
                                            <Grid item xs={12} sm={10}>
                                                <TableRow>
                                                    <TableCell style={{ borderBottom: "none", fontFamily: 'Times New Roman', fontSize: 16, padding: 10 }}>
                                                        <b>Company :</b> {dataOne.Company} <br />
                                                        <b>Poduct Name :</b> {dataOne.ProductName} <br />
                                                        <b>Poduct Category :</b> {dataOne.ProductCategory} <br />
                                                        <b> Product Code :</b> {dataOne.ProductCode} <br />
                                                    </TableCell>
                                                </TableRow>
                                            </Grid>
                                            <Grid item xs={12} sm={2}>
                                                <div style={{ marginTop: 10 }}>
                                                    <Tooltip title="Edit" aria-label="Edit">
                                                        <CreateIcon onClick={() => this.editItem(dataOne)} style={{ cursor: 'pointer', fontSize: 20, color: "#7f2a2a" }} />
                                                    </Tooltip>
&nbsp;&nbsp;&nbsp;
                  <Tooltip title="Delete" aria-label="Delete">
                                                        <DeleteIcon onClick={() => this.phoneClick(dataOne)} style={{ cursor: 'pointer', fontSize: 20, color: "#7f2a2a" }} />
                                                    </Tooltip>
                                                </div>
                                                <Modal
                                                    open={this.state.setOpen}
                                                    onClose={this.handleClose}
                                                    aria-labelledby="simple-modal-title"
                                                    aria-describedby="simple-modal-description"
                                                //style={{backgroundColor:"white"}}
                                                >
                                                    {body}
                                                </Modal>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                ))}
                            </TableBody>
                        }
                    </Table>
                }
                {this.state.target ?
                    null
                    :
                    <CircularProgress style={{
                        width: 100,
                        // size:5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', marginLeft: '40%', marginTop: 220
                    }} />
                }
            </div>
        )
    }
}

export default TemplateList;

