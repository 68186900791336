import { AuthProvider } from 'ra-core';
import { Auth } from 'aws-amplify';
//import { useHistory } from "react-router-dom";

//let history = useHistory();

const authProvider: AuthProvider = {
        login: ({ username, password }) => {
        // Auth.signIn(username, password)
        // .then(user => {
        //     console.log('successful sign in',user)
        // })
        // 	.catch(err => console.log(err));
        localStorage.setItem('username', username);
        // accept all username/password combinations
        return Promise.resolve();
    },
    logout: () => {
        localStorage.removeItem('username');
        Auth.signOut();
       // history.push("/");
        //this.props.history.push('/Login');
        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () =>
        localStorage.getItem('username') ? Promise.resolve() : Promise.reject(),
    getPermissions: () => Promise.reject('Unknown method'),
};

export default authProvider;
