import React from 'react';
import Avatar from '@material-ui/core/Avatar';
//import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PhoneIcon from '@material-ui/icons/Phone';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import CountryList from "../profile/CountryList";
import _ from "underscore";
//import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslate, Title } from 'react-admin';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
//import Modal from 'react-modal';
import { Auth } from 'aws-amplify';



 class Login extends React.Component{

    constructor(props){
  super(props);
  this.state={
  email:'',
  password:'',
  target: true
  
  }

    }

    sign = () => {

      this.props.history.push('/SignUp');


    }

    handleClickEmail = (e) => {

        this.setState({email : e.target.value});

    }

    handleClickPwd = (e) => {

        this.setState({password : e.target.value});

    }

    handleClick = () =>{
this.setState({target:false})
  

  Auth.signIn(this.state.email, this.state.password)
        .then(user => {
            // console.log('successful sign in',user);
            this.setState({target:true})
            this.props.history.push('/newProduct');
        })
        	.catch((error)=> {
            this.setState({target:true})
    // console.log(error);   
     alert("Please Enter Right Email or Password !");
   })

       

    }

 

        render(){
          return (

<Container component="main" maxWidth="xs">

<div style={{display: 'flex',  justifyContent:'center',marginTop:50}}>

    <Avatar>
          <LockOutlinedIcon />
        </Avatar>

    <Typography component="h1" variant="h5" style={{marginLeft: '0.8rem'}}>
          Sign in
        </Typography>

        </div>

<form noValidate>
          <TextField
            disabled={!this.state.target}
            variant="outlined"
            margin="normal"
            //required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            //autoComplete="email"
            value={this.state.email}
            onChange = {this.handleClickEmail}
          />
          <TextField
            disabled={!this.state.target}
            variant="outlined"
            margin="normal"
            //required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            //autoComplete="current-password"
            value={this.state.password}
            onChange = {this.handleClickPwd}
          />
         {this.state.target ?
          <Button
            //type="submit"
            fullWidth
            variant="contained"
            style={{backgroundColor:'#f79403',color:'white'}}
           // color="#f79403"
            //className={classes.submit}
            onClick={this.handleClick}
          >
            Sign In
          </Button>
          :
          <Container component="main" maxWidth="xs">

            <div style={{display: 'flex',  justifyContent:'center',marginTop:50}}>
            {/* <CircularProgress color="secondary" /> */}
            <CircularProgress color="secondary" />
            </div>
         </Container>  }
          <Grid container style={{marginTop:20,display: 'flex',  justifyContent:'center'}}>
            
            <Grid item onClick = {this.sign}>
             <u>
                {"Don't have an account? Sign Up"}
                </u>
              
            </Grid>
          </Grid>

          <br/>



        </form>

       </Container>
           
    
         )
   

   

    
}

    }

 




export default Login;
