/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:1b31b800-25b3-4b71-b131-78c7e7036d3e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_mj0YVBl77",
    "aws_user_pools_web_client_id": "6sieqnfnti0kb1al996vlsual",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "apic4a739ad",
            "endpoint": "https://vh1ri5l70m.execute-api.us-east-1.amazonaws.com/polyprod",
            "region": "us-east-1"
        },
        {
            "name": "testpolyprod",
            "endpoint": "https://u6n540stxe.execute-api.us-east-1.amazonaws.com/polyprod",
            "region": "us-east-1"
        },
        {
            "name": "dynmoDbTable",
            "endpoint": "https://jedb15wtsc.execute-api.us-east-1.amazonaws.com/polyprod",
            "region": "us-east-1"
        },
        {
            "name": "tushar",
            "endpoint": "https://3zphuj8tm8.execute-api.us-east-1.amazonaws.com/polyprod",
            "region": "us-east-1"
        },
        {
            "name": "mahesh",
            "endpoint": "https://kwh9zdaxmb.execute-api.us-east-1.amazonaws.com/polyprod",
            "region": "us-east-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "polycoatproducts-polyprod",
            "region": "us-east-1"
        },
        {
            "tableName": "polyprod-polyprod",
            "region": "us-east-1"
        },
        {
            "tableName": "tushar-polyprod",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
