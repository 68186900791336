import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import _ from "underscore";
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Auth, API } from 'aws-amplify';
import S3FileUpload from './ReactS3';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import Container from '@material-ui/core/Container';
import Autocomplete from '@material-ui/lab/Autocomplete';

const uuid = require('uuid');

class Product extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: '',
      image: '',
      dataArr: [],
      newArrForDisplay: [],
      OtherProductCategory:"",
      apiResponse: null,
      noteId: '',
      hover: false,
      checkedA: false,
      "PdfType": "",
      "PdfName": "",
      category: [],
      newArr: {
        "AssociatedProducts": [],
        "id": uuid.v1(),
        // "Company" : " ",
        "UrlKey": " ",
        "SerialNumber": " ",
        "MetaTags": [],
        "PDFlink": [],
        "Pictures": [],
        "PrimaryPic": [],
        "ProductCode": " ",
        "ProductBenefits": " ",
        "ProductCategory": " ",
        "ProductDesc": " ",
        "ProductFeatures": [""],
        "ProductFeaturesSmallList": [""],
        "AssociatedProducts": [],
        "ProductName": " ",
        "ProductShortDescription": [""],
        "Videolink": []
      },
      target: false
    }
    this.apicall = this.apicall.bind(this);
  }


  async componentDidMount() {
    const apiName = 'mahesh';
    const path = '/mahesh';
    const myInit = { // OPTIONAL
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param',
      // },

    };

    await API
      .get(apiName, path, myInit)
      .then(response => {
        let resData = [{ category: "Others" }]
        let resArr = []
        _.each(response.data, (resDat) => {
          if (resDat.ProductCategory.length > 2 && !resArr.includes(resDat.ProductCategory) && (typeof resDat.ProductCategory) === "string") {
            resArr.push(resDat.ProductCategory)
            resData.push({ category: resDat.ProductCategory })
          }
        })
        this.setState({ category: resData, newArrForDisplay: response.data, change: true, arrHolder: response.data })
      })
      .catch(error => {
        // console.log(error.response);
      });
  }


  primaryPic = async (event) => {
    const { newArr } = this.state;

    if (event.target.files.length > 0) {
      var data = event.target.files[0];
      if (data.type == "image/jpeg" || data.type == "image/jpg" || data.type == "image/png") {
        const config = {
          bucketName: 'test1-products',
          dirName: 'photos', /* optional */
          region: "us-east-1",
          accessKeyId: 'AKIATIBJIBPYLFAWUACV',
          secretAccessKey: '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',
        }
        var file = {
          name: data.name,
          type: 'multipart/form-data',
          body: data
        }
        S3FileUpload
          .uploadFile(file, config)
          .then((datas) => {
            newArr.PrimaryPic = { url: datas.location, name: data.name };
            this.setState({ change: false });
          })
          .catch(err => console.error(err))
      }
    }
  }
  apicall = async (event) => {
    this.setState({ change: true });
    const { newArr } = this.state;
    // console.log("event",event.target.files,event.target.files.length);
    if (event.target.files.length > 0) {
      _.each(event.target.files, (data, i) => {
        var file = {
          name: data.name,
          type: 'multipart/form-data',
          body: data
        }
        if (data.type == "image/jpeg" || data.type == "image/jpg" || data.type == "image/png") {
          const config = {
            bucketName: 'test1-products',
            dirName: 'photos', /* optional */
            region: "us-east-1",
            accessKeyId: 'AKIATIBJIBPYLFAWUACV',
            secretAccessKey: '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',
          }
          S3FileUpload
            .uploadFile(file, config)
            .then((datas) => {
              newArr.Pictures.push({ url: datas.location, name: data.name });
              this.setState({ change: false });
            })
            .catch(err => console.error(err))
        }
        if (data.type == "application/pdf" && this.state.PdfType) {
          const config = {
            bucketName: 'test1-products',
            dirName: 'pdfs', /* optional */
            region: "us-east-1",
            accessKeyId: 'AKIATIBJIBPYLFAWUACV',
            secretAccessKey: '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',
          }
          file.type = 'application/pdf';
          S3FileUpload
            .uploadFile(file, config)
            .then((datas) => {
              newArr.PDFlink.push({ url: datas.location, name: this.state.PdfName, PdfType: this.state.PdfType });
              this.setState({ change: false });
            })
            .catch(err => console.error(err))
        }
        if (data.type == "video/mp4") {
          const config = {
            bucketName: 'test1-products',
            dirName: 'videos', /* optional */
            region: "us-east-1",
            accessKeyId: 'AKIATIBJIBPYLFAWUACV',
            secretAccessKey: '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',
          }

          S3FileUpload
            .uploadFile(file, config)
            .then((datas) => {
              newArr.Videolink.push({ url: datas.location, name: data.name });
              this.setState({ change: false });
            })
            .catch(err => console.error(err))
        }
      })
    }
  }

  add = () => {

    this.state.newArr.ProductShortDescription.push("");
    this.setState({ change: false });

  }

  addFeature = () => {

    this.state.newArr.ProductFeatures.push("");
    this.setState({ change: false });

  }

  addFeatureSmallList = () => {

    this.state.newArr.ProductFeaturesSmallList.push("");
    this.setState({ change: false });

  }

  addCategory = (event) => {

    this.state.newArr.ProductCategory.push("");
    this.setState({ change: false });

  }

  removePrimaryPic = () => {
    this.state.newArr.PrimaryPic = " ";
    this.setState({ change: false })
  }

  removeImage = (i) => {

    // console.log("remove index",i);

    this.state.newArr.Pictures.splice(i, 1);
    this.setState({ change: false })


  }

  removePdf = (i) => {
    // console.log("remove index",i);
    this.state.newArr.PDFlink.splice(i, 1);
    this.setState({ change: false })
  }

  removeVideo = (i) => {
    // console.log("remove index",i);
    this.state.newArr.Videolink.splice(i, 1);
    this.setState({ change: false })
  }

  remove = (i) => {
    // console.log("remove index",i);
    this.state.newArr.ProductShortDescription.splice(i, 1);
    this.setState({ change: false })
  }

  removeFeature = (i) => {
    // console.log("remove index",i);      
    this.state.newArr.ProductFeatures.splice(i, 1);
    this.setState({ change: false })
  }

  removeFeatureSmallList = (i) => {
    // console.log("remove index",i);      
    this.state.newArr.ProductFeaturesSmallList.splice(i, 1);
    this.setState({ change: false })
  }

  removeCategory = (i) => {
    // console.log("remove index",i);      
    this.state.newArr.ProductCategory.splice(i, 1);
    this.setState({ change: false })
  }

  handleClickFirst = (event) => {
    // console.log("onChange ",event.target.value);
    if (event.target.value) {
      this.state.newArr.ProductCode = event.target.value;
    } else {
      this.state.newArr.ProductCode = " ";
    }
    this.setState({ changed: false })
  }

  handleClickSecond = (event) => {
    // console.log("onChange index",event.target.value);
    if (event.target.value) {
      this.state.newArr.ProductBenefits = event.target.value;
    } else {
      this.state.newArr.ProductBenefits = " ";
    }
    this.setState({ changed: false })
  }


  handleClickThird = (event) => {
    // console.log("onChange index",event.target.value);
    if (event.target.value) {
      this.state.newArr.ProductName = event.target.value;
    } else {
      this.state.newArr.ProductName = " ";
    }
    this.setState({ changed: false })
  }


  handleClickFourth = (event) => {
    // console.log("onChange index",event.target.value);
    if (event.target.value) {
      this.state.newArr.ProductDesc = event.target.value;
    } else {
      this.state.newArr.ProductDesc = " ";
    }
    this.setState({ changed: false })
  }

  handleClickUrlKey = (event) => {

    if (event.target.value) {
      this.state.newArr.UrlKey = event.target.value;
    } else {
      this.state.newArr.UrlKey = " ";
    }

    this.setState({ changed: false })

  }

  handleClickSerialNumber = (event) => {

    if (event.target.value) {
      this.state.newArr.SerialNumber = event.target.value;
    } else {
      this.state.newArr.SerialNumber = " ";
    }

    this.setState({ changed: false })

  }

  searchText = (event) => {

    console.log("event", event.target.value);

    const newData = _.filter(this.state.newArrForDisplay, item => {

      const productCodeData = item.ProductCode ? item.ProductCode.toUpperCase() : ''.toUpperCase();

      const ProductCode = event.target.value.toUpperCase();

      return (productCodeData.indexOf(ProductCode) > -1);


    })

    if (event.target.value == '') {
      this.setState({ newArrForDisplay: this.state.arrHolder });
    } else {
      this.setState({ newArrForDisplay: newData });
    }

  }

  prodShortDesc = (index, event) => {
    // console.log("onChange index",index,event.target.value);
    this.state.newArr.ProductShortDescription[index] = event.target.value;
    this.setState({ changed: false })
  }

  prodFeatures = (index, event) => {
    // console.log("onChange index",index,event.target.value);
    this.state.newArr.ProductFeatures[index] = event.target.value;
    this.setState({ changed: false })
  }

  prodFeaturesSmallList = (index, event) => {
    // console.log("onChange index",index,event.target.value);
    this.state.newArr.ProductFeaturesSmallList[index] = event.target.value;
    this.setState({ changed: false })
  }

  prodCat = (event, value) => {
    console.log("event", value, event.target.value);
    // console.log("onChange index",index,event.target.value);
    this.state.newArr.ProductCategory = (value && value.category) ? value.category : "";
    this.setState({ changed: false })
  }
  selectCompany = (event) => {
    console.log("event", event.target.value);
    // console.log("onChange index",index,event.target.value);
    this.state.newArr.Company = event.target.value;
    this.setState({ changed: false })
  }
  selectPdf = (event) => {
    console.log("event", event.target.value);
    // console.log("onChange index",index,event.target.value);
    this.state.PdfType = event.target.value;
    this.setState({ changed: false })
  }
  handleClickPdfName = (event) => {
    this.state.PdfName = event.target.value;
    this.setState({ changed: false })
  }
  save = async () => {
    if (this.state.newArr.ProductCategory === "Others") {
      this.state.newArr.ProductCategory = this.state.OtherProductCategory
    }
    setTimeout(async () => {
      const apiName = 'mahesh';
      const path = '/mahesh';
      const myInit = { // OPTIONAL
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
        response: true,
        body: this.state.newArr // OPTIONAL (return the entire Axios response object instead of only response.data)

      };

      await API
        .post(apiName, path, myInit)
        .then(response => {
          // console.log("response",response);
          this.props.history.push('/productList');
        })
        .catch(error => {
          // console.log(error.response);
        });
    }, 100);
  }

  toggleHover = () => {
    this.setState({ hover: !this.state.hover })
  }

  handleChange = (index, event, name, desc, pic) => {

    console.log("index", index, event.target.value, name, desc, pic);

    //this.state.checkedA[index]=event.target.checked;

    if (event.target.checked) {
      console.log("if");
      this.state.newArr.AssociatedProducts.push({ ProductName: name, ProductCode: event.target.value, ProductDesc: desc, PrimaryPic: pic });
      this.setState({ change: true })
    }
    else {
      console.log("else");
      this.state.newArr.AssociatedProducts = _.filter(this.state.newArr.AssociatedProducts, function (assocProd) {

        return assocProd.ProductCode != event.target.value;

      });
      //this.state.newArr.AssociatedProducts.splice(index,1);
      this.setState({ change: true })
    }

  }

  render() {
    console.log("this.state in prouct file is ", this.state);
    return (
      <Container component="main" >
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
          <Typography component="h1" variant="h5" style={{ marginLeft: '0.8rem', fontFamily: 'Times New Roman' }}>
            Add Product
              </Typography>
        </div>
        <br />
        <p style={{ color: 'red' }}>{this.state.error}</p>
        {this.state.newArr ?
          <div>
            <FormControl variant="outlined" style={{ minWidth: 1032, marginTop: 16 }}>
              <InputLabel id="demo-simple-select-outlined-label">Select Company</InputLabel>
              <Select

                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={this.state.newArr.Company}
                onChange={(event) => this.selectCompany(event)}
                label="Select Company"
              >
                <MenuItem value="GCMC">GCMC</MenuItem>
                <MenuItem value="Crown Polymers">Crown Polymers</MenuItem>
                <MenuItem value="Polycoat">Polycoat</MenuItem>
                <MenuItem value="Polytuff">Polytuff</MenuItem>
                <MenuItem value="EverRoof">EverRoof</MenuItem>
                <MenuItem value="UPC">UPC</MenuItem>
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="UrlKey"
              label="Url Key"
              //type="password"
              id="UrlKey"
              //autoComplete="current-password"
              value={this.state.newArr.UrlKey}
              onChange={(event) => this.handleClickUrlKey(event)}
            //disabled={true}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="SerialNumber"
              label="Serial Number"
              //type="password"
              id="SerialNumber"
              //autoComplete="current-password"
              value={this.state.newArr.SerialNumber}
              onChange={(event) => this.handleClickSerialNumber(event)}
            //disabled={true}
            />
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6}>

                <TextField
                  variant="outlined"
                  margin="normal"
                  //required
                  fullWidth
                  //name="firstName"
                  label="Product Code"
                  //type="password"
                  //id="firstName"
                  //autoComplete="current-password"
                  value={this.state.newArr.ProductCode}
                  onChange={(event) => this.handleClickFirst(event)}
                //disabled={true}
                />
                <div style={{ marginTop: 15 }}>

                  <TextField
                    id="standard-multiline-static"
                    label="Product Benefits"
                    multiline
                    variant="outlined"
                    rows={2}
                    fullWidth
                    value={this.state.newArr.ProductBenefits}
                    onChange={(event) => this.handleClickSecond(event)}
                  />

                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  //required
                  fullWidth
                  //id="email"
                  label="Product Name"
                  //name="email"
                  // autoComplete="email"
                  //autoFocus
                  value={this.state.newArr.ProductName}
                  //disabled={true}
                  onChange={(event) => this.handleClickThird(event)}
                />
                <div style={{ marginTop: 15 }}>

                  <TextField
                    id="standard-multiline-static"
                    label="Product Description"
                    multiline
                    variant="outlined"
                    rows={2}
                    fullWidth
                    value={this.state.newArr.ProductDesc}
                    onChange={(event) => this.handleClickFourth(event)}
                  />
                  {/* <TextareaAutosize
                    rowsMax={4}
                    aria-label="maximum height"
                    placeholder="Product Description"
                    //label="Product Description"
                  style={{width:486,height:50,overflow:'auto'}}
                    value={this.state.newArr.ProductDesc}
                    onChange = {(event)=>this.handleClickFourth(event)}
                  /> */}


                </div>

              </Grid>
            </Grid>

            <FormControl variant="outlined" style={{ minWidth: 1032, marginTop: 16 }}>
              <Grid container >
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    id="combo-box-demo"
                    autoSelect={true}
                    options={this.state.category}
                    getOptionLabel={(option) => option.category}
                    style={{
                      marginTop: 15,
                      // height: 40,
                      width: 300
                    }}
                    value={{ category: this.state.newArr.ProductCategory }}
                    onChange={this.prodCat}
                    renderInput={(params) =>
                      <TextField {...params} label="Select Category" variant="outlined"
                        value={this.state.newArr.ProductCategory}
                      // onChange={(e)=>this.handleStatusChange(e)}
                      />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    disabled={this.state.newArr.ProductCategory === "Others" ? false : true}
                    //required
                    fullWidth
                    name="Other Category"
                    label="Other Category"
                    //type="password"
                    //id="firstName"
                    //autoComplete="current-password"
                    value={this.state.OtherProductCategory}
                    onChange={(event) => this.setState({ OtherProductCategory: event.target.value })}
                    style={{ width: 300 }}
                  />
                </Grid>
              </Grid>
            </FormControl>
            <br /><br />
            <div style={{
              borderRadius: 25,
              border: "1px solid rgb(210, 201, 201)",
              padding: 20, marginTop: 20
            }}>
              <Typography >
                <div style={{ display: 'flex', justifyContent: 'center', fontSize: 18, fontFamily: 'Times New Roman' }}>
                  <u>Product Short Description</u> : &nbsp;&nbsp;
                          <Button
                    //type="submit"
                    //color="#f79403"
                    variant="contained"
                    style={{ width: 50, height: 30, backgroundColor: '#f79403', color: 'white' }}
                    //color="#f79403"
                    //className={classes.submit}
                    //onClick={this.handleClickSignUp}
                    onClick={this.add}
                  >
                    Add
                            </Button>
                </div>
              </Typography>
              <br /><br />
              <div>
                {this.state.newArr.ProductShortDescription.map((data, index) => (

                  <div style={{ marginTop: -60 }} >


                    <Grid container spacing={6}>
                      <Grid item xs={12} sm={1}>
                        <div style={{ marginTop: 30 }}>
                          <Tooltip title="Delete" aria-label="Delete" >
                            <ClearIcon onClick={() => this.remove(index)} style={{ cursor: 'pointer', fontSize: 26 }} />
                          </Tooltip>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={11}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          //required
                          fullWidth
                          name="firstName"
                          label="Desc"
                          //type="password"
                          //id="firstName"
                          //autoComplete="current-password"
                          value={this.state.newArr.ProductShortDescription[index]}
                          onChange={(event) => this.prodShortDesc(index, event)}
                          style={{ marginLeft: -50 }}
                        />




                      </Grid>



                    </Grid>
                    {/* <Tooltip title="Delete" aria-label="Delete">
                  <DeleteIcon onClick={()=>this.remove(index)} style={{cursor:'pointer'}} />
                  </Tooltip> */}


                  </div>

                ))
                }

              </div>
            </div>

            <div style={{
              borderRadius: 25,
              border: "1px solid rgb(210, 201, 201)",
              padding: 20, marginTop: 20
            }} >
              <Typography >
                <div style={{ display: 'flex', justifyContent: 'center', fontSize: 18, fontFamily: 'Times New Roman' }}>
                  <u>Product Features</u> : &nbsp;&nbsp;
                          <Button
                    //type="submit"
                    //color="#f79403"
                    variant="contained"
                    style={{ width: 50, height: 30, backgroundColor: '#f79403', color: 'white' }}
                    //color="#f79403"
                    //className={classes.submit}
                    //onClick={this.handleClickSignUp}
                    onClick={this.addFeature}
                  >
                    Add
                            </Button>
                </div>
              </Typography>
              <br /><br />

              <div>
                {this.state.newArr.ProductFeatures.map((data, index) => (

                  <div style={{ marginTop: -60 }} >
                    <Grid container spacing={6}>
                      <Grid item xs={12} sm={1}>
                        <div style={{ marginTop: 30 }}>
                          <Tooltip title="Delete" aria-label="Delete" >
                            <ClearIcon onClick={() => this.removeFeature(index)} style={{ cursor: 'pointer', fontSize: 26 }} />
                          </Tooltip>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={11}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          //required
                          fullWidth
                          name="firstName"
                          label="Feature"
                          //type="password"
                          //id="firstName"
                          //autoComplete="current-password"
                          value={this.state.newArr.ProductFeatures[index]}
                          onChange={(event) => this.prodFeatures(index, event)}
                          style={{ marginLeft: -50 }}
                        />

                      </Grid>



                    </Grid>
                    {/* <Tooltip title="Delete" aria-label="Delete">
                  <DeleteIcon onClick={()=>this.removeFeature(index)} style={{cursor:'pointer'}} />
                  </Tooltip> */}


                  </div>

                ))
                }

              </div>

            </div>

            <div style={{
              borderRadius: 25,
              border: "1px solid rgb(210, 201, 201)",
              padding: 20, marginTop: 20
            }} >
              <Typography >
                <div style={{ display: 'flex', justifyContent: 'center', fontSize: 18, fontFamily: 'Times New Roman' }}>
                  <u>Small Feature List</u> : &nbsp;&nbsp;
                          <Button
                    //type="submit"
                    //color="#f79403"
                    variant="contained"
                    style={{ width: 50, height: 30, backgroundColor: '#f79403', color: 'white' }}
                    //color="#f79403"
                    //className={classes.submit}
                    //onClick={this.handleClickSignUp}
                    onClick={this.addFeatureSmallList}
                  >
                    Add
                            </Button>
                </div>
              </Typography>
              <br /><br />

              <div>
                {this.state.newArr.ProductFeaturesSmallList.map((data, index) => (

                  <div style={{ marginTop: -60 }} >
                    <Grid container spacing={6}>
                      <Grid item xs={12} sm={1}>
                        <div style={{ marginTop: 30 }}>
                          <Tooltip title="Delete" aria-label="Delete" >
                            <ClearIcon onClick={() => this.removeFeatureSmallList(index)} style={{ cursor: 'pointer', fontSize: 26 }} />
                          </Tooltip>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={11}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          //required
                          fullWidth
                          name="firstName"
                          label="Feature"
                          //type="password"
                          //id="firstName"
                          //autoComplete="current-password"
                          value={this.state.newArr.ProductFeaturesSmallList[index]}
                          onChange={(event) => this.prodFeaturesSmallList(index, event)}
                          style={{ marginLeft: -50 }}
                        />

                      </Grid>



                    </Grid>
                    {/* <Tooltip title="Delete" aria-label="Delete">
                  <DeleteIcon onClick={()=>this.removeFeature(index)} style={{cursor:'pointer'}} />
                  </Tooltip> */}


                  </div>

                ))
                }

              </div>

            </div>


            <div style={{
              borderRadius: 25,
              border: "1px solid rgb(210, 201, 201)",
              padding: 20, marginTop: 20
            }} >
              <Typography >
                <div style={{ display: 'flex', justifyContent: 'center', fontSize: 18, fontFamily: 'Times New Roman' }}>
                  <u style={{ marginTop: 4 }}>Associated Products :</u>
                  {this.state.newArr.Company &&
                    <TextField
                      variant="outlined"
                      margin="normal"
                      //required
                      width={200}
                      size="small"
                      style={{ width: 200, marginTop: 0, marginBottom: 0, marginLeft: 15 }}
                      // name="Pdf Name"
                      label="Search"
                      //type="password"
                      id="Search"
                      //autoComplete="current-password"
                      //value={this.state.PdfName}
                      onChange={(event) => this.searchText(event)}
                    //disabled={this.state.change}
                    />
                  }

                </div>
              </Typography>
              <br /><br />

              <div style={{ height: 126, overflowY: "scroll", overflowX: "hidden" }}>
                {this.state.newArr.Company ?
                  <div>
                    <Grid container style={{ marginLeft: 36 }}>
                      {this.state.newArrForDisplay.map((data, index) => (

                        data.Company == this.state.newArr.Company &&

                        <Grid item xs={12} sm={4}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.checkedA[index]}
                                  onChange={(event) => this.handleChange(index, event, data.ProductName, data.ProductDesc, (data.PrimaryPic && data.PrimaryPic.url) ? data.PrimaryPic.url : " ")}
                                  name="checkedA"
                                  color="primary"
                                  value={data.ProductCode}
                                />
                              }
                              label={data.ProductCode}
                            />


                          </FormGroup>
                        </Grid>
                      ))}

                    </Grid>
                  </div>

                  :
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <p>Please select a Company</p>
                  </div>

                }

              </div>

            </div>
            <div>



              <div>
                <Grid container>

                  <Grid item xs={12} sm={6}>
                    <Typography >
                      <div style={{ marginTop: 30, display: 'flex', justifyContent: 'center', fontSize: 18, fontFamily: 'Times New Roman' }}>
                        <u>Select Primary Image</u> : &nbsp;&nbsp;

          </div>
                    </Typography>
                    <br />
                    <div style={{
                      width: 500, height: 150, borderStyle: 'dashed', borderWidth: 3,
                      borderColor: '#ddd',
                      borderRadius: 1, position: 'relative'
                    }}>
                      <div style={{ textAlign: 'center' }}>
                        <img src={require("../images/index.png")} style={{ width: 80, height: 80, marginTop: 10 }} />
                        <p style={{ fontSize: 20, fontFamily: 'Rockwell' }}>Drop an Image here</p>

                        {/* <p style={{fontFamily:'Times New Roman'}}>or Click to choose.</p> */}


                        <input type="file" accept="image/*" multiple="multiple"
                          style={{ width: 500, height: 150, position: 'absolute', top: 0, left: 0, textIndent: -9999, cursor: 'pointer' }}
                          onChange={(event) => this.primaryPic(event)} />
                      </div>
                    </div>

                    {this.state.newArr.PrimaryPic.url ?
                      <div style={{ display: 'flex', marginTop: 20, justifyContent: "center" }}>

                        <div style={{ marginLeft: 20, position: "relative" }}>
                          <img src={this.state.newArr.PrimaryPic.url} style={{ height: 100, width: 100, objectFit: "cover" }} />
                          <p style={{ wordBreak: "break-all", width: 100 }}>{this.state.newArr.PrimaryPic.name}</p>
                          <Tooltip title="Delete" aria-label="Delete">
                            <ClearIcon onClick={() => this.removePrimaryPic()} style={{ cursor: 'pointer', position: "absolute", top: 0, right: 0, color: "red" }} />
                          </Tooltip>

                          &nbsp;&nbsp;


                           </div>


                      </div>

                      : null}


                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography >
                      <div style={{ marginTop: 30, display: 'flex', justifyContent: 'center', fontSize: 18, fontFamily: 'Times New Roman' }}>
                        <u>Select Secondary Image</u> : &nbsp;&nbsp;

          </div>
                    </Typography>
                    <br />
                    <div style={{
                      width: 500, height: 150, borderStyle: 'dashed', borderWidth: 3,
                      borderColor: '#ddd',
                      borderRadius: 1, position: 'relative'
                    }}>
                      <div style={{ textAlign: 'center' }}>
                        <img src={require("../images/index.png")} style={{ width: 80, height: 80, marginTop: 10 }} />
                        <p style={{ fontSize: 20, fontFamily: 'Rockwell' }}>Drop an Image here</p>

                        {/* <p style={{fontFamily:'Times New Roman'}}>or Click to choose.</p> */}


                        <input type="file" accept="image/*" multiple="multiple"
                          style={{ width: 500, height: 150, position: 'absolute', top: 0, left: 0, textIndent: -9999, cursor: 'pointer' }}
                          onChange={(event) => this.apicall(event)} />
                      </div>
                    </div>
                    {this.state.newArr.Pictures.length > 0 ?
                      <div style={{ display: 'flex', marginTop: 20, justifyContent: "center" }}>
                        {this.state.newArr.Pictures.map((data, index) => (
                          <div style={{ marginLeft: 20, position: "relative" }}>
                            <img src={data.url} style={{ height: 100, width: 100, objectFit: "cover" }} />
                            <p style={{ wordBreak: "break-all", width: 100 }}>{data.name}</p>
                            <Tooltip title="Delete" aria-label="Delete">
                              <ClearIcon onClick={() => this.removeImage(index)} style={{ cursor: 'pointer', position: "absolute", top: 0, right: 0, color: "red" }} />
                            </Tooltip>

                            &nbsp;&nbsp;

                          </div>

                        ))}

                      </div>

                      : null}
                  </Grid>

                </Grid>
                <br />
                <Grid container>

                  <Grid item xs={12} sm={6}>
                    <Typography >
                      <div style={{ marginTop: 30, display: 'flex', alignItems: "center", justifyContent: 'center', fontSize: 18, fontFamily: 'Times New Roman' }}>
                        <u>Select PDF</u> : &nbsp;&nbsp;
        <FormControl variant="outlined" style={{ minWidth: 180 }}>
                          <InputLabel id="demo-simple-select-outlined-label" style={{ marginTop: -7 }}>Select PDF Type</InputLabel>
                          <Select

                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.PdfType}
                            onChange={(event) => this.selectPdf(event)}
                            label="Select PDF Type"
                            style={{ height: 40 }}
                            // disabled={this.state.change}
                          >
                            <MenuItem value="TDS">TDS</MenuItem>
                            <MenuItem value="Brochure">Brochure</MenuItem>
                            <MenuItem value="Promotional">Promotional</MenuItem>
                            <MenuItem value="Additional">Additional PDFs</MenuItem>
                          </Select>
                        </FormControl>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          width={200}
                          size="small"
                          style={{ width: 200, marginTop: 0, marginBottom: 0, marginLeft: 5 }}
                          // name="Pdf Name"
                          label="Pdf Name"
                          //type="password"
                          id="PdfName"
                          //autoComplete="current-password"
                          value={this.state.PdfName}
                          onChange={(event) => this.handleClickPdfName(event)}
                          // disabled={this.state.change}
                        />
                      </div>
                    </Typography>
                    <br />
                    <div style={{
                      width: 500, height: 150, borderStyle: 'dashed', borderWidth: 3,
                      borderColor: '#ddd',
                      borderRadius: 1, position: 'relative'
                    }}>
                      <div style={{ textAlign: 'center' }}>
                        <img src={require("../images/index.png")} style={{ width: 80, height: 80, marginTop: 10 }} />
                        {(this.state.PdfType && this.state.PdfName) ?
                          <p style={{ fontSize: 20, fontFamily: 'Rockwell' }}>Drop an {this.state.PdfType} PDF here</p>
                          : this.state.PdfType ?
                            <p style={{ fontSize: 20, fontFamily: 'Rockwell' }}>Please provide PDF name to proceed</p>
                            :
                            <p style={{ fontSize: 20, fontFamily: 'Rockwell' }}>Please select PDF Type to proceed</p>
                        }
                        {/* <p style={{fontFamily:'Times New Roman'}}>or Click to choose.</p> */}


                        <input type="file" accept=".pdf" multiple="multiple" disabled={(!this.state.PdfType || !this.state.PdfName || this.state.change)}
                          style={{ width: 500, height: 150, position: 'absolute', top: 0, left: 0, textIndent: -9999, cursor: 'pointer' }}
                          onChange={(event) => this.apicall(event)} />
                      </div>
                    </div>

                    {this.state.newArr.PDFlink.length > 0 ?
                      <div style={{ display: 'flex', marginTop: 20, justifyContent: "center", alignItems: "end" }}>
                        {this.state.newArr.PDFlink.map((data, index) => (
                          // data.PdfType == "TDS" &&
                          <div style={{ marginLeft: 5, position: "relative", display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: 'center', }}>
                            <p style={{ wordBreak: "break-all", width: 75, textAlign: "center", marginTop: 0, marginBottom: 0 }}>{data.PdfType}</p>
                            <img src={require("../images/application-pdf.png")} style={{ marginTop: 0, height: 50, width: 50, objectFit: "scale-down" }} />
                            <p style={{ wordBreak: "break-all", width: 75, textAlign: "center", marginTop: 0 }}>{data.name}</p>
                            <Tooltip title="Delete" aria-label="Delete">
                              <ClearIcon onClick={() => this.removePdf(index)} style={{ cursor: 'pointer', position: "absolute", top: 13, right: -10, color: "red" }} />
                            </Tooltip>

                            &nbsp;&nbsp;
                          </div>

                        ))}

                      </div>

                      : null}


                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography >
                      <div style={{ marginTop: 42, display: 'flex', justifyContent: 'center', fontSize: 18, fontFamily: 'Times New Roman' }}>
                        <u style={{ marginTop: -7 }}>Select Video</u> : &nbsp;&nbsp;

          </div>
                    </Typography>
                    <br />
                    <div style={{
                      width: 500, height: 150, borderStyle: 'dashed', borderWidth: 3,
                      borderColor: '#ddd',
                      borderRadius: 1, position: 'relative'
                    }}>
                      <div style={{ textAlign: 'center' }}>
                        <img src={require("../images/index.png")} style={{ width: 80, height: 80, marginTop: 10 }} />
                        <p style={{ fontSize: 20, fontFamily: 'Rockwell' }}>Drop an Video here</p>

                        {/* <p style={{fontFamily:'Times New Roman'}}>or Click to choose.</p> */}


                        <input type="file" accept="video/*" multiple="multiple"
                          style={{ width: 500, height: 150, position: 'absolute', top: 0, left: 0, textIndent: -9999, cursor: 'pointer' }}
                          onChange={(event) => this.apicall(event)} />
                      </div>
                    </div>
                    {this.state.newArr.Videolink.length > 0 ?
                      <div style={{ display: 'flex', marginTop: 20, justifyContent: "center" }}>
                        {this.state.newArr.Videolink.map((data, index) => (
                          <div style={{ marginLeft: 20, position: "relative" }}>
                            <video src={data.url} style={{ height: 100, width: 100 }} controls />
                            <p style={{ wordBreak: "break-all", width: 100 }}>{data.name}</p>

                            <Tooltip title="Delete" aria-label="Delete">
                              <ClearIcon onClick={() => this.removeVideo(index)} style={{ cursor: 'pointer', position: "absolute", top: 0, right: 0, color: "red" }} />
                            </Tooltip>

                            &nbsp;&nbsp;
                          </div>

                        ))}

                      </div>

                      : null}
                  </Grid>

                </Grid>

              </div>
            </div>
            <br /><br />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>

              <Button
                //type="submit"
                // fullWidth
                variant="contained"
                style={{ backgroundColor: '#f79403', color: 'white', width: 500 }}
                //color="#f79403"
                //className={classes.submit}
                onClick={this.save}
              >
                Save
          </Button>

            </div>


          </div>

          : null}

      </Container>


    )

  }
}

export default Product;