import React from 'react';
import Avatar from '@material-ui/core/Avatar';
//import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslate, Title } from 'react-admin';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import ClearIcon from '@material-ui/icons/Clear';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
//import Modal from 'react-modal';
import _ from "underscore";
import S3FileUpload from './ReactS3';
import { Auth, API } from 'aws-amplify';
import Autocomplete from '@material-ui/lab/Autocomplete';


const fetch = require('node-fetch');

class EditTemplate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: '',
      dataArr: [],
      category: [],
      target: false,
      showData: true,
      PdfType: "",
      PdfName: "",
      checkedA: false,
      prodList: [],
      loader: false,
      counter: 1,
      autoFocus: false,
      OtherProductCategory: ""
    }
    this.add = this.add.bind(this);
    this.apicall = this.apicall.bind(this);
  }


  async componentDidMount() {

    this.setState({ target: true })
    const apiName = 'mahesh';
    const path = '/mahesh';
    const myInit = { // OPTIONAL
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param',
      // },

    };
    let resData = [{ category: "Others" }]
    let resArr = []

    await API
      .get(apiName, path, myInit)
      .then(response => {
        _.each(response.data, (resDat) => {
          console.log("error.response", resDat);
          if (resDat.ProductCategory.length > 2 && !resArr.includes(resDat.ProductCategory) && (typeof resDat.ProductCategory) === "string") {
            resArr.push(resDat.ProductCategory)
            resData.push({ category: resDat.ProductCategory })
          }
        })
      })
      .catch(error => {
        // console.log(error.response);
      });
    let arrayProd = [];
    await this.props.location.state && this.props.location.state.name.AssociatedProducts.map((data, index) => (
      arrayProd.push(data.ProductCode)
    ))
    this.props.location.state && this.props.location.state.dataArray.map((data, index) => {
      if (arrayProd.includes(data.ProductCode)) {
        data.checked = true;
      } else {
        data.checked = false;
      }
    })

    setTimeout(() => {
      this.setState({ category: resData, change: true, arrHolder: this.props.location.state ? this.props.location.state.dataArray : [], prodList: this.props.location.state ? this.props.location.state.dataArray : [], loader: true })
    }, 3000);

  }
  primaryPic = async (event) => {
    //const { newArr } = this.state;

    if (event.target.files.length > 0) {
      var data = event.target.files[0];
      if (data.type == "image/jpeg" || data.type == "image/jpg" || data.type == "image/png") {
        const config = {
          bucketName: 'test1-products',
          dirName: 'photos', /* optional */
          region: "us-east-1",
          accessKeyId: 'AKIATIBJIBPYLFAWUACV',
          secretAccessKey: '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',
        }
        var file = {
          name: data.name,
          type: 'multipart/form-data',
          body: data
        }
        S3FileUpload
          .uploadFile(file, config)
          .then((datas) => {
            this.props.location.state.name.PrimaryPic = { url: datas.location, name: data.name };
            //this.setState({ change: true });
          })
          .catch(err => console.error(err))
      }
    }
  }
  apicall = async (event) => {
    this.setState({ change: true });
    // console.log("event",event.target.files,event.target.files.length);
    if (event.target.files.length > 0) {
      _.each(event.target.files, (data, i) => {
        var file = {
          name: data.name,
          type: 'multipart/form-data',
          body: data
        }
        if (data.type == "image/jpeg" || data.type == "image/jpg" || data.type == "image/png") {
          const config = {
            bucketName: 'test1-products',
            dirName: 'photos', /* optional */
            region: "us-east-1",
            accessKeyId: 'AKIATIBJIBPYLFAWUACV',
            secretAccessKey: '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',
          }
          S3FileUpload
            .uploadFile(file, config)
            .then((datas) => {
              this.props.location.state.name.Pictures.push({ url: datas.location, name: data.name });
              this.setState({ change: false });
            })
            .catch(err => console.error(err))
        }
        if (data.type == "application/pdf" && this.state.PdfType) {
          const config = {
            bucketName: 'test1-products',
            dirName: 'pdfs', /* optional */
            region: "us-east-1",
            accessKeyId: 'AKIATIBJIBPYLFAWUACV',
            secretAccessKey: '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',
          }
          file.type = 'application/pdf';
          S3FileUpload
            .uploadFile(file, config)
            .then((datas) => {
              this.props.location.state.name.PDFlink.push({ url: datas.location, name: this.state.PdfName, PdfType: this.state.PdfType });
              this.setState({ change: false });
            })
            .catch(err => console.error(err))
        }
        if (data.type == "video/mp4") {
          const config = {
            bucketName: 'test1-products',
            dirName: 'videos', /* optional */
            region: "us-east-1",
            accessKeyId: 'AKIATIBJIBPYLFAWUACV',
            secretAccessKey: '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',
          }

          S3FileUpload
            .uploadFile(file, config)
            .then((datas) => {
              this.props.location.state.name.Videolink.push({ url: datas.location, name: data.name });
              this.setState({ change: false });
            })
            .catch(err => console.error(err))
        }
      })
    }
  }

  add = () => {

    this.props.location.state.name.ProductShortDescription.push("");
    this.setState({ change: false });

  }

  searchText = (event) => {

    console.log("event", event.target.value);

    const newData = _.filter(this.state.prodList, item => {

      const productCodeData = item.ProductCode ? item.ProductCode.toUpperCase() : ''.toUpperCase();

      const ProductCode = event.target.value.toUpperCase();

      return (productCodeData.indexOf(ProductCode) > -1);


    })

    if (event.target.value == '') {
      this.setState({ prodList: this.state.arrHolder });
    } else {
      this.setState({ prodList: newData });
    }

  }

  addFeature = () => {

    this.props.location.state.name.ProductFeatures.push("");
    this.setState({ change: false });

  }
  addFeatureSmallList = () => {

    this.props.location.state.name.ProductFeaturesSmallList.push("");
    this.setState({ change: false });

  }
  selectPdf = (event) => {
    console.log("event", event.target.value);
    // console.log("onChange index",index,event.target.value);
    this.state.PdfType = event.target.value;
    this.setState({ changed: false })
  }
  addCategory = () => {

    this.props.location.state.name.ProductCategory.push("");
    this.setState({ change: false });

  }

  removeImage = (i) => {

    // console.log("remove index",i);

    this.props.location.state.name.Pictures.splice(i, 1);
    this.setState({ change: false })


  }

  removeFeatureSmallList = (i) => {
    // console.log("remove index",i);      
    this.props.location.state.name.ProductFeaturesSmallList.splice(i, 1);
    this.setState({ change: false })
  }

  removePrimaryPic = () => {
    this.props.location.state.name.PrimaryPic = " ";
    this.setState({ change: false })
  }


  removePdf = (i) => {

    // console.log("remove index",i);

    this.props.location.state.name.PDFlink.splice(i, 1);
    this.setState({ change: false })


  }

  removeVideo = (i) => {

    // console.log("remove index",i);

    this.props.location.state.name.Videolink.splice(i, 1);
    this.setState({ change: false })


  }

  remove = (i) => {

    // console.log("remove index",i);


    this.props.location.state.name.ProductShortDescription.splice(i, 1);
    this.setState({ change: false })


  }

  removeFeature = (i) => {

    // console.log("remove index",i);


    this.props.location.state.name.ProductFeatures.splice(i, 1);
    this.setState({ change: false })


  }

  removeCategory = (i) => {

    // console.log("remove index",i);


    this.props.location.state.name.ProductCategory.splice(i, 1);
    this.setState({ change: false })


  }

  handleClickFirst = (event) => {

    // console.log("onChange ",event.target.value);

    if (event.target.value) {
      this.props.location.state.name.ProductCode = event.target.value;
    } else {
      this.props.location.state.name.ProductCode = " ";
    }

    this.setState({ changed: false })

  }

  handleClickSecond = (event) => {

    // console.log("onChange index",event.target.value);
    if (event.target.value) {
      this.props.location.state.name.ProductBenefits = event.target.value;
    } else {
      this.props.location.state.name.ProductBenefits = " ";
    }

    this.setState({ changed: false })

  }


  handleClickThird = (event) => {

    if (event.target.value) {
      this.props.location.state.name.ProductName = event.target.value;
    } else {
      this.props.location.state.name.ProductName = " ";
    }

    this.setState({ changed: false })

  }


  handleClickFourth = (event) => {

    if (event.target.value) {
      this.props.location.state.name.ProductDesc = event.target.value;
    } else {
      this.props.location.state.name.ProductDesc = " ";
    }

    this.setState({ changed: false })

  }

  handleClickUrlKey = (event) => {

    if (event.target.value) {
      this.props.location.state.name.UrlKey = event.target.value;
    } else {
      this.props.location.state.name.UrlKey = " ";
    }

    this.setState({ changed: false })

  }

  handleClickSerialNumber = (event) => {

    if (event.target.value) {
      this.props.location.state.name.SerialNumber = event.target.value;
    } else {
      this.props.location.state.name.SerialNumber = " ";
    }

    this.setState({ changed: false })

  }
  handleClickPdfName = (event) => {
    this.state.PdfName = event.target.value;
    this.setState({ changed: false })
  }

  handleClickPdfNameChange = (event, index) => {
    console.log("index", index);
    //this.state.PdfName = event.target.value;
    this.props.location.state.name.PDFlink[index].name = event.target.value;
    this.setState({ changed: false })
  }

  prodShortDesc = (index, event) => {

    // console.log("onChange index",index,event.target.value);

    this.props.location.state.name.ProductShortDescription[index] = event.target.value;

    this.setState({ changed: false })


  }

  prodFeatures = (index, event) => {
    // console.log("onChange index",index,event.target.value);
    this.props.location.state.name.ProductFeatures[index] = event.target.value;
    this.setState({ changed: false })
  }

  prodFeaturesSmallList = (index, event) => {
    // console.log("onChange index",index,event.target.value);
    this.props.location.state.name.ProductFeaturesSmallList[index] = event.target.value;
    this.setState({ changed: false })
  }

  prodCat = (event, value) => {
    this.props.location.state.name.ProductCategory = (value && value.category) ? value.category : "";
    this.setState({ changed: false })
  }
  selectCompany = (event) => {
    this.props.location.state.name.Company = event.target.value;
    this.setState({ changed: false })
  }

  // editPdfName = (data,index) => {

  //   console.log("index",index,data);
  //   this.setState({autoFocus:true});

  // }

  save = async () => {
    if (this.props.location.state.name.ProductCategory === "Others") {
      this.props.location.state.name.ProductCategory = this.state.OtherProductCategory
    }
    setTimeout(async () => {
      const apiName = 'mahesh';
      const path = '/mahesh';
      const myInit = { // OPTIONAL
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        body:
        {
          "Key": {
            "id": this.props.location.state.name.id
          },
          "UpdateExpression": "SET PrimaryPic= :pic,  ProductBenefits= :p, #a= :pc, ProductName= :b,ProductDesc= :c,ProductShortDescription= :d,ProductFeatures= :e,ProductCategory= :f,Pictures = :g,PDFlink = :h,Videolink = :i, UrlKey = :j, Company = :k, AssociatedProducts = :l, SerialNumber = :m, ProductFeaturesSmallList = :n",
          "ExpressionAttributeNames": {
            "#a": "ProductCode"
          },
          "ExpressionAttributeValues": {
            ":pc": this.props.location.state.name.ProductCode,
            ":p": this.props.location.state.name.ProductBenefits,
            ":b": this.props.location.state.name.ProductName,
            ":c": this.props.location.state.name.ProductDesc,
            ":d": this.props.location.state.name.ProductShortDescription,
            ":e": this.props.location.state.name.ProductFeatures,
            ":f": this.props.location.state.name.ProductCategory,
            ":g": this.props.location.state.name.Pictures,
            ":h": this.props.location.state.name.PDFlink,
            ":i": this.props.location.state.name.Videolink,
            ":j": this.props.location.state.name.UrlKey ? this.props.location.state.name.UrlKey : ' ',
            ":k": this.props.location.state.name.Company ? this.props.location.state.name.Company : ' ',
            ":l": this.props.location.state.name.AssociatedProducts,
            ":pic": this.props.location.state.name.PrimaryPic,
            ":m": this.props.location.state.name.SerialNumber ? this.props.location.state.name.SerialNumber : ' ',
            ":n": this.props.location.state.name.ProductFeaturesSmallList ? this.props.location.state.name.ProductFeaturesSmallList : [""],
          },
          "TableName": "products"
        }
      };
      await API
        .put(apiName, path, myInit)
        .then(response => {
          // console.log("response",response);
          this.props.history.push('/productList');
        })
        .catch(error => {
          console.log(error.response);
        });
    }, 100);
  }

  handleChange = (index, event, name, desc, pic) => {

    console.log("index", index, event.target.value, name, desc, pic);

    //this.state.checkedA[index]=event.target.checked;

    if (event.target.checked) {
      console.log("if");
      this.props.location.state.name.AssociatedProducts.push({ ProductName: name, ProductCode: event.target.value, ProductDesc: desc, PrimaryPic: pic });
      this.state.prodList[index].checked = event.target.checked;
      this.setState({ change: true })
    }
    else {
      console.log("else");
      this.state.prodList[index].checked = event.target.checked;
      this.props.location.state.name.AssociatedProducts = _.filter(this.props.location.state.name.AssociatedProducts, function (assocProd) {

        return assocProd.ProductCode != event.target.value;

      });
      //this.state.newArr.AssociatedProducts.splice(index,1);
      this.setState({ change: true })
    }

  }

  render() {
    console.log("state", this.state.showData, this.props.location.state === undefined)
    if (this.state.showData && (this.props.location === undefined || this.props.location.state === undefined || this.props.location.state.name === undefined)) {
      this.setState({ showData: false })
      this.props.history.push("/productList");

    } else {
      console.log("state", this.state);
      console.log("state props", this.props.location.state.name);

      return (
        <Container component="main" >
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
            <Typography component="h1" variant="h5" style={{ marginLeft: '0.8rem', fontFamily: 'Times New Roman' }}>
              Edit Template
                        </Typography>
          </div>
          <br />

          <p style={{ color: 'red' }}>{this.state.error}</p>
          <form noValidate>
            {this.state.showData ?
              <div>
                <FormControl variant="outlined" style={{ minWidth: 1032, marginTop: 16 }}>
                  <InputLabel id="demo-simple-select-outlined-label">Select Company</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={this.props.location.state.name.Company}
                    onChange={(event) => this.selectCompany(event)}
                    label="Select Company"
                  >
                    <MenuItem value="GCMC">GCMC</MenuItem>
                    <MenuItem value="Crown Polymers">Crown Polymers</MenuItem>
                    <MenuItem value="Polycoat">Polycoat</MenuItem>
                    <MenuItem value="Polytuff">Polytuff</MenuItem>
                    <MenuItem value="EverRoof">EverRoof</MenuItem>
                    <MenuItem value="UPC">UPC</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="UrlKey"
                  label="Url Key"
                  //type="password"
                  id="UrlKey"
                  //autoComplete="current-password"
                  value={this.props.location.state.name.UrlKey ? this.props.location.state.name.UrlKey : ' '}
                  onChange={(event) => this.handleClickUrlKey(event)}
                //disabled={true}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="SerialNumber"
                  label="Serial Number"
                  //type="password"
                  id="SerialNumber"
                  //autoComplete="current-password"
                  value={this.props.location.state.name.SerialNumber ? this.props.location.state.name.SerialNumber : ' '}
                  onChange={(event) => this.handleClickSerialNumber(event)}
                //disabled={true}
                />

                <Grid container spacing={6}>
                  <Grid item xs={12} sm={6}>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="firstName"
                      label="Product Code"
                      //type="password"
                      id="firstName"
                      //autoComplete="current-password"
                      value={this.props.location.state.name.ProductCode}
                      onChange={(event) => this.handleClickFirst(event)}
                    //disabled={true}
                    />
                    <div style={{ marginTop: 15 }}>

                      <TextField
                        id="standard-multiline-static"
                        label="Product Benefits"
                        multiline
                        variant="outlined"
                        rows={2}
                        fullWidth
                        value={this.props.location.state.name.ProductBenefits}
                        onChange={(event) => this.handleClickSecond(event)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Product Name"
                      name="email"
                      // autoComplete="email"
                      //autoFocus
                      value={this.props.location.state.name.ProductName}
                      //disabled={true}
                      onChange={(event) => this.handleClickThird(event)}
                    />
                    <div style={{ marginTop: 15 }}>
                      <TextField
                        id="standard-multiline-static"
                        label="Product Description"
                        multiline
                        variant="outlined"
                        rows={2}
                        fullWidth
                        value={this.props.location.state.name.ProductDesc}
                        onChange={(event) => this.handleClickFourth(event)}
                      />
                    </div>

                  </Grid>
                </Grid>

                <FormControl variant="outlined" style={{ minWidth: 1032, marginTop: 16 }}>
                  <Grid container >
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        id="combo-box-demo"
                        autoSelect={true}
                        options={this.state.category}
                        getOptionLabel={(option) => option.category}
                        style={{
                          marginTop: 15,
                          // height: 40,
                          width: 300
                        }}
                        value={{ category: this.props.location.state.name.ProductCategory }}
                        onChange={this.prodCat}
                        renderInput={(params) =>
                          <TextField {...params} label="Select Category" variant="outlined"
                            value={this.props.location.state.name.ProductCategory}
                          // onChange={(e)=>this.handleStatusChange(e)}
                          />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        disabled={this.props.location.state.name.ProductCategory === "Others" ? false : true}
                        //required
                        fullWidth
                        name="Other Category"
                        label="Other Category"
                        //type="password"
                        //id="firstName"
                        //autoComplete="current-password"
                        value={this.state.OtherProductCategory}
                        onChange={(event) => this.setState({ OtherProductCategory: event.target.value })}
                        style={{ width: 300 }}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
                <br /><br />

                <div style={{
                  borderRadius: 25,
                  border: "1px solid rgb(210, 201, 201)",
                  padding: 20, marginTop: 20
                }}>
                  <Typography >
                    <div style={{ display: 'flex', justifyContent: 'center', fontSize: 18, fontFamily: 'Times New Roman' }}>
                      <u>Product Short Description</u> : &nbsp;&nbsp;
                        <Button
                        //type="submit"
                        //color="#f79403"
                        variant="contained"
                        style={{ width: 50, height: 30, backgroundColor: '#f79403', color: 'white' }}
                        //color="#f79403"
                        //className={classes.submit}
                        //onClick={this.handleClickSignUp}
                        onClick={this.add}
                      >
                        Add
                          </Button>
                    </div>
                  </Typography>
                  <br /><br />
                  <div>
                    {this.props.location.state.name.ProductShortDescription.map((data, index) => (
                      <div style={{ marginTop: -60 }}>
                        <Grid container spacing={6}>

                          <Grid item xs={12} sm={1}>
                            <div style={{ marginTop: 30 }}>
                              <Tooltip title="Delete" aria-label="Delete" >
                                <ClearIcon onClick={() => this.remove(index)} style={{ cursor: 'pointer', fontSize: 26 }} />
                              </Tooltip>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={11}>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              name="firstName"
                              label="Desc"
                              //type="password"
                              //id="firstName"
                              //autoComplete="current-password"
                              value={this.props.location.state.name.ProductShortDescription[index]}
                              onChange={(event) => this.prodShortDesc(index, event)}
                              style={{ marginLeft: -50 }}
                            />
                          </Grid>
                        </Grid>


                      </div>
                    ))
                    }
                  </div>
                </div>
                <div style={{
                  borderRadius: 25,
                  border: "1px solid rgb(210, 201, 201)",
                  padding: 20, marginTop: 20
                }}>
                  <Typography >
                    <div style={{ display: 'flex', justifyContent: 'center', fontSize: 18, fontFamily: 'Times New Roman' }}>
                      <u>Product Features</u> : &nbsp;&nbsp;
                        <Button
                        //type="submit"
                        //color="#f79403"
                        variant="contained"
                        style={{ width: 50, height: 30, backgroundColor: '#f79403', color: 'white' }}
                        //color="#f79403"
                        //className={classes.submit}
                        //onClick={this.handleClickSignUp}
                        onClick={this.addFeature}
                      >
                        Add
                          </Button>
                    </div>
                  </Typography>
                  <br /><br />
                  <div>
                    {this.props.location.state.name.ProductFeatures.map((data, index) => (
                      <div style={{ marginTop: -60 }}>
                        <Grid container spacing={6}>
                          <Grid item xs={12} sm={1}>
                            <div style={{ marginTop: 30 }}>
                              <Tooltip title="Delete" aria-label="Delete" >
                                <ClearIcon onClick={() => this.removeFeature(index)} style={{ cursor: 'pointer', fontSize: 26 }} />
                              </Tooltip>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={11}>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              name="firstName"
                              label="Feature"
                              //type="password"
                              //id="firstName"
                              //autoComplete="current-password"
                              value={this.props.location.state.name.ProductFeatures[index]}
                              onChange={(event) => this.prodFeatures(index, event)}
                              style={{ marginLeft: -50 }}
                            />
                          </Grid>
                        </Grid>

                      </div>
                    ))
                    }
                  </div>
                </div>

                <div style={{
                  borderRadius: 25,
                  border: "1px solid rgb(210, 201, 201)",
                  padding: 20, marginTop: 20
                }}>
                  <Typography >
                    <div style={{ display: 'flex', justifyContent: 'center', fontSize: 18, fontFamily: 'Times New Roman' }}>
                      <u>Small Feature List</u> : &nbsp;&nbsp;
                        <Button
                        //type="submit"
                        //color="#f79403"
                        variant="contained"
                        style={{ width: 50, height: 30, backgroundColor: '#f79403', color: 'white' }}
                        //color="#f79403"
                        //className={classes.submit}
                        //onClick={this.handleClickSignUp}
                        onClick={this.addFeatureSmallList}
                      >
                        Add
                          </Button>
                    </div>
                  </Typography>
                  <br /><br />
                  <div>
                    {((this.props.location.state.name.ProductFeaturesSmallList && this.props.location.state.name.ProductFeaturesSmallList.length > 0) ? this.props.location.state.name.ProductFeaturesSmallList : []).map((data, index) => (
                      <div style={{ marginTop: -60 }}>
                        <Grid container spacing={6}>
                          <Grid item xs={12} sm={1}>
                            <div style={{ marginTop: 30 }}>
                              <Tooltip title="Delete" aria-label="Delete" >
                                <ClearIcon onClick={() => this.removeFeatureSmallList(index)} style={{ cursor: 'pointer', fontSize: 26 }} />
                              </Tooltip>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={11}>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              name="firstName"
                              label="Feature"
                              //type="password"
                              //id="firstName"
                              //autoComplete="current-password"
                              value={this.props.location.state.name.ProductFeaturesSmallList[index]}
                              onChange={(event) => this.prodFeaturesSmallList(index, event)}
                              style={{ marginLeft: -50 }}
                            />
                          </Grid>
                        </Grid>

                      </div>
                    ))
                    }
                  </div>
                </div>

                <div style={{
                  borderRadius: 25,
                  border: "1px solid rgb(210, 201, 201)",
                  padding: 20, marginTop: 20
                }} >
                  <Typography >
                    <div style={{ display: 'flex', justifyContent: 'center', fontSize: 18, fontFamily: 'Times New Roman' }}>
                      <u style={{ marginTop: 4 }}>Associated Products :</u>

                      <TextField
                        variant="outlined"
                        margin="normal"
                        //required
                        width={200}
                        size="small"
                        style={{ width: 200, marginTop: 0, marginBottom: 0, marginLeft: 15 }}
                        // name="Pdf Name"
                        label="Search"
                        //type="password"
                        id="Search"
                        //autoComplete="current-password"
                        //value={this.state.PdfName}
                        onChange={(event) => this.searchText(event)}
                      //disabled={this.state.change}
                      />

                    </div>
                  </Typography>
                  <br /><br />

                  <div style={{ height: 126, overflowY: "scroll", overflowX: "hidden", marginLeft: 70 }}>

                    {this.state.loader ?

                      <Grid container>
                        {this.state.prodList.map((data, index) => (

                          (data.ProductCode != this.props.location.state.name.ProductCode && data.Company == this.props.location.state.name.Company) &&

                          <Grid item xs={12} sm={4}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.checked}
                                    onChange={(event) => this.handleChange(index, event, data.ProductName, data.ProductDesc, (data.PrimaryPic && data.PrimaryPic.url) ? data.PrimaryPic.url : " ")}
                                    name="checkedA"
                                    color="primary"
                                    value={data.ProductCode}
                                  />
                                }
                                label={data.ProductCode}
                              />


                            </FormGroup>
                          </Grid>
                        ))}

                      </Grid>

                      :
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {/* <CircularProgress color="secondary" /> */}
                        <CircularProgress color="secondary" />
                      </div>

                    }
                  </div>
                </div>
                <div>
                  <div>
                    <Grid container>

                      <Grid item xs={12} sm={6}>
                        <Typography >
                          <div style={{ marginTop: 30, display: 'flex', justifyContent: 'center', fontSize: 18, fontFamily: 'Times New Roman' }}>
                            <u>Select Primary Image</u> : &nbsp;&nbsp;
          </div>
                        </Typography>
                        <br />
                        <div style={{
                          width: 500, height: 150, borderStyle: 'dashed', borderWidth: 3,
                          borderColor: '#ddd',
                          borderRadius: 1, position: 'relative'
                        }}>
                          <div style={{ textAlign: 'center' }}>
                            <img src={require("../images/index.png")} style={{ width: 80, height: 80, marginTop: 10 }} />
                            <p style={{ fontSize: 20, fontFamily: 'Rockwell' }}>Drop an Image here</p>

                            {/* <p style={{fontFamily:'Times New Roman'}}>or Click to choose.</p> */}


                            <input type="file" accept="image/*" multiple="multiple"
                              style={{ width: 500, height: 150, position: 'absolute', top: 0, left: 0, textIndent: -9999, cursor: 'pointer' }}
                              onChange={(event) => this.primaryPic(event)} />
                          </div>
                        </div>

                        {(this.props.location.state.name.PrimaryPic && this.props.location.state.name.PrimaryPic.url) ?
                          <div style={{ display: 'flex', marginTop: 20, justifyContent: "center" }}>

                            <div style={{ marginLeft: 20, position: "relative" }}>
                              <img src={this.props.location.state.name.PrimaryPic.url} style={{ height: 100, width: 100, objectFit: "cover" }} />
                              <p style={{ wordBreak: "break-all", width: 100 }}>{this.props.location.state.name.PrimaryPic.name}</p>
                              <Tooltip title="Delete" aria-label="Delete">
                                <ClearIcon onClick={() => this.removePrimaryPic()} style={{ cursor: 'pointer', position: "absolute", top: 0, right: 0, color: "red" }} />
                              </Tooltip>

                              &nbsp;&nbsp;


                           </div>


                          </div>

                          : null}


                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography >
                          <div style={{ marginTop: 30, display: 'flex', justifyContent: 'center', fontSize: 18, fontFamily: 'Times New Roman' }}>
                            <u>Select Secondary Image</u> : &nbsp;&nbsp;

          </div>
                        </Typography>
                        <br />
                        <div style={{
                          width: 500, height: 150, borderStyle: 'dashed', borderWidth: 3,
                          borderColor: '#ddd',
                          borderRadius: 1, position: 'relative'
                        }}>
                          <div style={{ textAlign: 'center' }}>
                            <img src={require("../images/index.png")} style={{ width: 80, height: 80, marginTop: 10 }} />
                            <p style={{ fontSize: 20, fontFamily: 'Rockwell' }}>Drop an Image here</p>

                            {/* <p style={{fontFamily:'Times New Roman'}}>or Click to choose.</p> */}


                            <input type="file" accept="image/*" multiple="multiple"
                              style={{ width: 500, height: 150, position: 'absolute', top: 0, left: 0, textIndent: -9999, cursor: 'pointer' }}
                              onChange={(event) => this.apicall(event)} />
                          </div>
                        </div>
                        {this.props.location.state.name.Pictures.length > 0 ?
                          <div style={{ display: 'flex', marginTop: 20, justifyContent: "center" }}>
                            {this.props.location.state.name.Pictures.map((data, index) => (
                              <div style={{ marginLeft: 20, position: "relative" }}>
                                <img src={data.url} style={{ height: 100, width: 100, objectFit: "cover" }} />
                                <p style={{ wordBreak: "break-all", width: 100 }}>{data.name}</p>
                                <Tooltip title="Delete" aria-label="Delete">
                                  <ClearIcon onClick={() => this.removeImage(index)} style={{ cursor: 'pointer', position: "absolute", top: 0, right: 0, color: "red" }} />
                                </Tooltip>

                                &nbsp;&nbsp;

                              </div>

                            ))}

                          </div>

                          : null}
                      </Grid>

                    </Grid>
                    <br />
                    <Grid container>

                      <Grid item xs={12} sm={6}>
                        <Typography >
                          <div style={{ marginTop: 30, display: 'flex', alignItems: "center", justifyContent: 'center', fontSize: 18, fontFamily: 'Times New Roman' }}>
                            <u>Select PDF</u> : &nbsp;&nbsp;
        <FormControl variant="outlined" style={{ minWidth: 200 }}>
                              <InputLabel id="demo-simple-select-outlined-label" style={{ marginTop: -7 }}>Select PDF Type</InputLabel>
                              <Select

                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.PdfType}
                                onChange={(event) => this.selectPdf(event)}
                                label="Select PDF Type"
                                style={{ height: 40 }}
                              //disabled={this.state.change}
                              >
                                <MenuItem value="TDS">TDS</MenuItem>
                                <MenuItem value="Brochure">Brochure</MenuItem>
                                <MenuItem value="Promotional">Promotional</MenuItem>
                                <MenuItem value="Additional">Additional PDFs</MenuItem>
                              </Select>
                            </FormControl>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              width={200}
                              size="small"
                              style={{ width: 200, marginTop: 0, marginBottom: 0, marginLeft: 5 }}
                              // name="Pdf Name"
                              label="Pdf Name"
                              //type="password"
                              id="PdfName"
                              //autoComplete="current-password"
                              value={this.state.PdfName}
                              onChange={(event) => this.handleClickPdfName(event)}
                            //disabled={this.state.change}
                            />
                          </div>
                        </Typography>
                        <br />
                        <div style={{
                          width: 500, height: 150, borderStyle: 'dashed', borderWidth: 3,
                          borderColor: '#ddd',
                          borderRadius: 1, position: 'relative'
                        }}>
                          <div style={{ textAlign: 'center' }}>
                            <img src={require("../images/index.png")} style={{ width: 80, height: 80, marginTop: 10 }} />
                            {(this.state.PdfType && this.state.PdfName) ?
                              <p style={{ fontSize: 20, fontFamily: 'Rockwell' }}>Drop an {this.state.PdfType} PDF here</p>
                              : this.state.PdfType ?
                                <p style={{ fontSize: 20, fontFamily: 'Rockwell' }}>Please provide PDF name to proceed</p>
                                :
                                <p style={{ fontSize: 20, fontFamily: 'Rockwell' }}>Please select PDF Type to proceed</p>
                            }
                            <input type="file" accept=".pdf" multiple="multiple"
                              //disabled={(!this.state.PdfType || !this.state.PdfName || this.state.change)}
                              style={{ width: 500, height: 150, position: 'absolute', top: 0, left: 0, textIndent: -9999, cursor: 'pointer' }}
                              onChange={(event) => this.apicall(event)} />
                          </div>
                        </div>

                        {this.props.location.state.name.PDFlink.length > 0 ?
                          <div style={{ display: 'flex', marginTop: 20, justifyContent: "center", alignItems: "end" }}>
                            <TableContainer style={{ borderRadius: 25, height: 200, overflowY: 'scroll' }}>
                              <Table aria-label="simple table" >
                                <TableHead>
                                  <TableRow>
                                    <TableCell style={{ fontWeight: 700 }}>S.No.</TableCell>
                                    <TableCell align="" style={{ fontWeight: 700 }}>Pdf Type</TableCell>
                                    <TableCell align="" style={{ fontWeight: 700 }}>Pdf Name</TableCell>
                                    <TableCell align="" style={{ fontWeight: 700 }}>Edit</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.props.location.state.name.PDFlink.map((data, index) => (
                                    <TableRow>
                                      <TableCell align="">{index + 1}</TableCell>
                                      <TableCell align="">{data.PdfType}</TableCell>
                                      <TableCell align="">
                                        <TextField
                                          variant="outlined"
                                          margin="normal"
                                          id="PdfName"
                                          value={data.name}
                                          //autoFocus={this.state.autoFocus}
                                          onChange={(event) => this.handleClickPdfNameChange(event, index)}
                                        //disabled={this.state.change}
                                        />
                                      </TableCell>
                                      {/* <TableCell align=""><Button variant="outlined" color="primary" onClick={() => this.editPdfName(data,index)}>Edit</Button></TableCell> */}
                                      <TableCell align=""><Button variant="outlined" color="secondary" onClick={() => this.removePdf(index)}>Delete</Button></TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                          : null}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography >
                          <div style={{ marginTop: 42, display: 'flex', justifyContent: 'center', fontSize: 18, fontFamily: 'Times New Roman' }}>
                            <u style={{ marginTop: -7 }}>Select Video</u> : &nbsp;&nbsp;
                        </div>
                        </Typography>
                        <br />
                        <div style={{
                          width: 500, height: 150, borderStyle: 'dashed', borderWidth: 3,
                          borderColor: '#ddd',
                          borderRadius: 1, position: 'relative'
                        }}>
                          <div style={{ textAlign: 'center' }}>
                            <img src={require("../images/index.png")} style={{ width: 80, height: 80, marginTop: 10 }} />
                            <p style={{ fontSize: 20, fontFamily: 'Rockwell' }}>Drop an Video here</p>

                            {/* <p style={{fontFamily:'Times New Roman'}}>or Click to choose.</p> */}


                            <input type="file" accept="video/*" multiple="multiple"
                              style={{ width: 500, height: 150, position: 'absolute', top: 0, left: 0, textIndent: -9999, cursor: 'pointer' }}
                              onChange={(event) => this.apicall(event)} />
                          </div>
                        </div>
                        {this.props.location.state.name.Videolink.length > 0 ?
                          <div style={{ display: 'flex', marginTop: 20, justifyContent: "center" }}>
                            {this.props.location.state.name.Videolink.map((data, index) => (
                              <div style={{ marginLeft: 20, position: "relative" }}>
                                <video src={data.url} style={{ height: 100, width: 100 }} controls />
                                <p style={{ wordBreak: "break-all", width: 100 }}>{data.name}</p>

                                <Tooltip title="Delete" aria-label="Delete">
                                  <ClearIcon onClick={() => this.removeVideo(index)} style={{ cursor: 'pointer', position: "absolute", top: 0, right: 0, color: "red" }} />
                                </Tooltip>
                                &nbsp;&nbsp;
                              </div>
                            ))}
                          </div>
                          : null}
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <br /><br />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    //type="submit"
                    // fullWidth
                    variant="contained"
                    style={{ backgroundColor: '#f79403', color: 'white', width: 500 }}
                    //color="#f79403"
                    //className={classes.submit}
                    onClick={this.save}
                  >
                    Save
                          </Button>
                </div>
              </div>
              : null}
          </form>
          {this.state.target ?
            null
            :
            <CircularProgress style={{
              width: 100,
              // size:5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center', marginLeft: '45%', marginTop: 160
            }} />
          }
        </Container>
      )
    }
    return null;
  }
}

export default EditTemplate;