import React from 'react';
import { Route } from 'react-router-dom';
import Login from './layout/Login';
import SignUp from './layout/SignUp';
import EditTemplate from './template/editTemplate';
import TemplateList from './template/templateList';
import Product from './template/Product';


export default [
     <Route exact path="/Login" render={() => <Login />} component={Login} />,
     <Route exact path="/SignUp" render={() => <SignUp />} component={SignUp} noLayout />,
     <Route exact path="/editTemplate" render={() => <EditTemplate />} component={EditTemplate} />,
    <Route exact path="/productList" render={() => <TemplateList />} component={TemplateList} />,
    <Route exact path="/newProduct" render={() => <Product />} component={Product} />,
];
