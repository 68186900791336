import React, { useState, useEffect } from 'react';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { withAuthenticator } from 'aws-amplify-react';
import './App.css';
import authProvider from './authProvider';
import { Login, Layout, SignUp } from './layout';
import customRoutes from './routes';
import englishMessages from './i18n/en';
import Product from './template/Product';
import dataProviderFactory from './dataProvider';
import Amplify, { Auth } from 'aws-amplify';

import API, { graphqlOperation } from '@aws-amplify/api';
import awsmobile from './aws-exports';


Amplify.configure(awsmobile);


const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }

    // Always fallback on english
    return englishMessages;
}, 'en');

const App = () => {
    const [dataProvider, setDataProvider] = useState(null);

    useEffect(() => {



        let restoreFetch;

        const fetchDataProvider = async () => {
            const dataProviderInstance= restoreFetch = await dataProviderFactory(
                process.env.REACT_APP_DATA_PROVIDER
            );
            setDataProvider(
                // GOTCHA: dataProviderInstance can be a function
                () => dataProviderInstance
            );
        };

        fetchDataProvider();

        return restoreFetch;
    }, []);

    if (!dataProvider) {
        return (
            <div className="loader-container">
                <div className="loader">Loading...</div>
            </div>
        );
    }

    return (
        <Admin
            title=""
            dataProvider={dataProvider}
            //customReducers={{ theme: themeReducer }}
            customRoutes={customRoutes}
            authProvider={authProvider}
            //dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            //i18nProvider={i18nProvider}
        >
             {/* <Resource name="customers" {...visitors} />
            <Resource
                name="commands"
                {...orders}
                options={{ label: 'Orders' }}
            />
            <Resource name="invoices" {...invoices} />
            <Resource name="products" {...products} />
            <Resource name="categories" {...categories} /> */}
            <Resource name="Login" {...Login} /> 
        </Admin>
    );
};

export default App;
// export default withAuthenticator(App);